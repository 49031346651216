@import "../../../styles/var";

.faq {
    padding-bottom: 80px;

    @media (min-width: $md5+px) {
        padding-bottom: 120px;
    }
}

.faq__container {
    position: relative;
    z-index: 2;
}

.faq__header {
    @media (min-width: $md2+px) {
        margin-right: 60px;
    }
}

.faq__title {
    margin-bottom: 20px;

    color: #054165;

    font-size: 20px;
    font-weight: 500;
    font-family: $inter;
    line-height: 140%;

    @media (min-width: $md5+px) {
        font-size: 24px;
    }

    @media (min-width: $md3+px) {
        margin-bottom: 40px;
    }
}

.faq__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (min-width: $md3+px) {
        flex-direction: row;
    }
}

.faq__contacts {
    padding: 45px 20px;
    margin-top: 80px;

    color: white;

    border-radius: 20px;
    background: #054165;

    @media (min-width: $md5+px) {
        padding: 40px 60px;
    }

    @media (min-width: $md3+px) {
        margin-top: 0;
        margin-left: 20px;
    }
}

.faq__contactsTitle {
    margin: 0;
    margin-bottom: 20px;

    color: #FFF;

    font-family: $lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1.4px;

    @media (min-width: $md5+px) {
        font-size: 40px;
    }
}

.faq__contactsDescr {
    margin: 0;
    margin-bottom: 12px;

    color: #FFF;

    font-family: $inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.faq__contactsDescr + .faq__contactsDescr {
    margin-bottom: 40px;

    color: rgba(255, 255, 255, 0.70);

    @media (min-width: $md2+px) {
        margin-bottom: 12px;
    }
}

.faq__contactsList {
    color: #FFF;

    font-family: $inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
}

.faq__contactsItem + .faq__contactsItem {
    margin-top: 14px;
}

.faq__contactsItem {
    a {
        color: inherit;
    }

    &::before {
        content: "";

        position: relative;
        bottom: -6px;

        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 14px;

        background-repeat: no-repeat;
        background-position: center;
    }

    //&:nth-child(1)::before {
    //    background-image: url("../../../assets/img/main/icon-tel.png");
    //}

    &:nth-child(1)::before {
        background-image: url("../../../assets/img/main/icon-email.png");
    }

    //&:nth-child(3)::before {
    //    background-image: url("../../../assets/img/main/icon-address.png");
    //}
}
