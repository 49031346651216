@import '../../../styles/var';

.tabs {
  max-width: 730px;

  @media (min-width: $md2+px) {
    max-width: 780px;
  }

  .tab + .tab {
    margin-top: 12px;
  }

  .tab {
    position: relative;
    padding: 18px 40px;

    cursor: pointer;

    transition: .2s ease-in-out;

    color: rgba(0, 0, 0, 0.70);
    background: #DEEAF1;

    border-radius: 16px;

    &:last-child::after {
      top: auto;
      bottom: 0;
    }

    &Active {
      .tabBtn::before {
        transform: translate(-50%, -50%);
      }

      .tabText {
        transform: scale(1);
        visibility: visible;
        opacity: 1;
        position: static;

        display: flex;
        margin-top: 12px;

        color: inherit;
      }
    }

    &Title {
      position: relative;

      padding-right: 35px;
      margin-right: -15px;

      color: inherit;
      font-family: $inter;
      font-weight: 500;
      font-size: 16px;
    }

    &Btn {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);

      display: block;
      width: 30px;
      height: 30px;

      background: #054165;
      border-radius: 50%;
      cursor: pointer;

      &::after,
      &::before {
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: block;
        width: 15px;
        height: 2px;

        background: #FFFFFF;
        transition: .2s ease-in;
      }

      &::before {
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &Text {
      transform: scale(0);
      visibility: hidden;
      opacity: 0;
      position: absolute;
      color: inherit;

      transition: .2s ease-in-out;
    }
  }
}
