@import "../../../styles/var";

.button {
  padding: 13px 30px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  background: #054165;
  //box-shadow: 0px 0px 1px 0px #FE9839, 0px 0px 3px 0px #FE9839, 0px 0px 11px 0px #FE9839, 0px 0px 22px 0px #FE9839, 0px 0px 38px 0px #FE9839, 0px 0px 66px 0px #FE9839;
  border-radius: 25px;
  font-family: $inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  transition: all 0.3s;
  user-select: none;



  &:hover {
    color: white;
    background: rgba(#054165, .7);
  }

  &.buttonWhite {
    color: #054165;
    background-color: white;
  }
}
