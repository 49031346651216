@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&family=Roboto:wght@300;400&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Lato:wght@400;700&display=swap');

@font-face {
  font-family: "Lato";
  src: url("../assets/Lato-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("../assets/Lato-Semibold.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  src: url("../assets/Lato-Bold.woff2") format("woff2");
  font-weight: 700;
}
