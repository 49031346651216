@import "../../../styles/var";

.features {
  position: relative;

  padding-top: 120px;

  @media (min-width: $md5+px) {
    //padding-top: 180px;
    //padding-bottom: 160px;
  }

  @media (min-width: $md2+px) {
    padding-bottom: 0;
  }
}

.features__terms {
  display: flex;
  flex-direction: column;
  margin-bottom: 140px;

  @media (min-width: $md3+px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 160px;
  }
}

.features__termsItem {
  max-width: 620px;
}

.features__termsItem + .features__termsItem {
  margin-top: 80px;

  @media (min-width: $md5+px) {
    margin-top: 100px;
  }

  @media (min-width: $md3+px) {
    margin-top: 0;
    margin-left: 20px;
  }
}

.features__termsTitle {
  margin: 0;
  margin-bottom: 20px;

  color: #054165;

  font-family: $lato;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.4px;

  @media (min-width: $md5+px) {
    font-size: 36px;
  }

  @media (min-width: $md2+px) {
    font-size: 40px;
  }
}

.features__termsDescr {
  color: rgba(0, 0, 0, 0.70);

  font-family: $inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
}

.features__header {
  width: 100%;
  padding: 36px 20px 50px;

  color: #fff;
  text-align: center;

  border-radius: 20px;
  background: #054165;
}

.features__headerImg {
  margin-bottom: 20px;
}

.features__headerTitle {
  margin: 0;
  margin-bottom: 10px;

  font-family: $inter;
  font-size: 20px;
  font-weight: 500;
}

.features__headerDescr {
  margin: 0;
  color: rgba(255, 255, 255, 0.70);

  font-family: $inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
}

.features__title {
  margin: 0;
  margin-bottom: 40px;

  color: #FFE0C5;

  text-align: center;
  text-shadow: 0px 0px 3px #FF871A, 0px 0px 6px #FF871A, 0px 0px 21px #FF871A, 0px 0px 42px #FF871A, 0px 0px 72px #FF871A, 0px 0px 126px #FF871A;
  font-family: $manrope;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;

  @media (min-width: $md5+px) {
    font-size: 32px;
  }

  @media (min-width: $md2+px) {
    margin-bottom: 60px;
    font-size: 40px;
    text-align: left;
  }
}

.features__subtitle {
  display: block;
  margin-bottom: 12px;
  color: #FFF;

  font-family: $roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  text-align: center;
}

.features__descr {
  display: block;
  margin-bottom: 100px;

  color: rgba(0, 0, 0, 0.70);

  text-align: center;
  font-family: $roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.features__listWrap {
  padding: 0 20px 120px;
  margin-left: -18px;
  overflow-x: scroll;

  @media (min-width: $md5+px) {
    position: relative;
    z-index: 4;


    padding: 0 20px 140px;
    margin: 0;
    overflow: visible;
  }

  @media (min-width: $md2+px) {
    padding: 0 28px 160px;
    margin-right: -18px;
  }
}

.features__list {
  display: flex;
  flex-direction: column;
  margin-right: -15px;

  @media (min-width: $md5+px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (min-width: $md2+px) {
    justify-content: space-between;
    padding-bottom: 0;
  }
}

.features__item {
  position: relative;
  z-index: 2;

  flex-shrink: 0;
  width: 100%;
  padding: 50px 20px 40px;
  margin-top: 20px;
  margin-right: 30px;

  border-radius: 20px;
  backdrop-filter: blur(11px);
  background: #fff;
  box-shadow: inset 0 0 0 2px #FBFBFB;
  border-radius: 40px;
  background: #DEEAF1;

  &:first-child {
    margin-top: 60px;

    .features__itemDecoDot {
      width: 35px;
      height: 35px;
      border: 2px dashed #CAE3DA;
    }

    .features__itemDecoDot::before {
      content: "";
    }

    .features__itemDecoDot::after {
      height: 45px;
    }
  }

  @media (min-width: $md5+px) {
    width: 322px;
    margin-right: 15px;
    margin-top: 60px;
  }

  @media (min-width: $md2+px) {
    width: 392px;
    margin-right: 15px;
  }
}

.features__itemDecoDot {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);

  display: block;

  background: white;
  border-radius: 50%;

  @media (min-width: $md5+px) {
    width: 35px;
    height: 35px;
    border: 2px dashed #CAE3DA;
  }

  &::before {
    @media (min-width: $md5+px) {
      content: "";
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    width: 14px;
    height: 14px;

    background: #054165;
    border-radius: 50%;
  }

  &::after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 100%;
    z-index: 0;
    transform: translateX(-50%);

    height: 22px;
    border-left: 2px solid #CAE3DA;

    @media (min-width: $md5+px) {
      height: 45px;
    }
  }
}

.features__itemIcon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 100px;
  filter: drop-shadow(0px 0px 0px #FF871A) drop-shadow(0px 0px 21px #FF871A);
}

.features__itemTitle {
  margin: 0;
  margin-bottom: 10px;

  color: #054165;

  text-align: center;
  font-family: $inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.features__itemDescr {
  color: rgba(0, 0, 0, 0.70);

  text-align: center;
  font-family: $inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
}

.features__itemFeatures {
  padding: 0;
  margin: 0;
  margin-bottom: 34px;

  list-style: none;
}

.features__itemFeature {
  position: relative;

  padding-left: 24px;
  margin: 0;
  margin-bottom: 8px;

  color: rgba(0, 0, 0, 0.70);

  font-family: $roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;

  &::before {
    content: "";

    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    display: block;
    width: 6px;
    height: 6px;

    background: #FF8719;
    border-radius: 50%;

    @media (min-width: $md2+px) {
      width: 8px;
      height: 8px;
    }
  }

  @media (min-width: $md2+px) {
    font-size: 16px;
  }
}

.features__img {
  position: absolute;
  bottom: 0;
  right: 0;

  width: 332px;
  height: 343px;

  @media (min-width: $md5+px) {
    width: 666px;
    height: 666px;
  }

  @media (min-width: $md2+px) {
    width: auto;
    height: auto;
  }
}
