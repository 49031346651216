@import "../../../styles/var";

.advantages {
    position: relative;

    padding-bottom: 500px;

    @media (min-width: $md2+px) {
        padding-bottom: 210px;
    }
}

.advantages__container {
    display: flex;
    flex-direction: column;

    @media (min-width: $md4+px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.advantages__content {
    @media (min-width: $md4+px) {
        padding-right: 60px;
    }
}

.advantages__preview {
    margin-top: 40px;

    @media (min-width: $md4+px) {
        margin-top: 0;
    }
}

.advantages__previewImg {
    display: block;
    width: 335px;
    height: 341px;
    margin: 0 auto;
    object-fit: contain;

    @media (min-width: $md3+px) {
        width: 679px;
        height: 560px;
    }
}

.advantages__title {
    max-width: 480px;
    margin: 0;
    margin-bottom: 40px;

    color: #054165;

    font-family: $lato;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (min-width: $md5+px) {
        font-size: 36px;
    }

    @media (min-width: $md3+px) {
        font-size: 40px;
    }
}

.advantages__descr {
    margin: 0;
    margin-bottom: 20px;
    color: #2F2F2F;

    color: rgba(0, 0, 0, 0.70);

    font-family: $inter;
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;

    @media (min-width: $md5+px) {
        //font-size: 24px;
    }

    @media (min-width: $md2+px) {
        max-width: 552px;
        //font-size: 28px;
    }
}

.advantages__descrWrap {
    margin-bottom: 40px;
    //display: flex;
    //flex-direction: column;

    @media (min-width: $md5+px) {
        //flex-direction: row;
    }
}

.advantages__checkList + .advantages__checkList {
    margin-top: 35px;

    @media (min-width: $md5+px) {
        margin-top: auto;
        margin-left: 67px;
    }
}

.advantages__checkList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.advantages__checkItem + .advantages__checkItem {
    margin-top: 35px;
}

.advantages__checkItem {
    position: relative;

    padding-left: 55px;

    color: rgba(0, 0, 0, 0.70);

    font-family: $manrope;
    font-size: 14px;
    line-height: 1.5;

    @media (min-width: $md5+px) {
        max-width: 400px;
        font-size: 16px;
    }

    @media (min-width: $md2+px) {
        font-size: 20px;
    }

    &::before {
        content: "";

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        display: block;
        width: 33px;
        height: 28px;

        //background: url("../../../assets/img/MarketsPage/icon-confirm.svg");
        background-size: contain;

        filter: drop-shadow(0px 0px 3px #FF871A) drop-shadow(0px 0px 6px #FF871A) drop-shadow(0px 0px 21px #FF871A) drop-shadow(0px 0px 42px #FF871A);
    }
}

.advantages__imgWrap {
    max-width: 322px;
    width: 100%;
}

.advantages__img {
    position: relative;
    bottom: -3px;

    display: block;
    width: 100%;
}

.advantages {
    position: relative;

    padding-top: 20px;
    padding-bottom: 120px;

    @media (min-width: $md5+px) {
        padding-bottom: 140px;
    }

    @media (min-width: $md2+px) {
        padding-bottom: 120px;
    }
}

.advantages__header {
    display: flex;
    margin-bottom: 80px;
}

.advantages__headerLeft {
    max-width: 627px;
    width: 100%;
    margin-right: 60px;
}

.advantages__headerTitle {
    display: block;
    margin-bottom: 20px;

    color: #FF8719;

    font-family: $manrope;
    font-size: 40px;
    font-weight: 500;
    line-height: 140%;

    @media (min-width: $md2+px) {
        line-height: 1;
    }
}

.advantages__headerDescr {
    color: rgba(0, 0, 0, 0.70);
    font-family: $roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
}

.advantages__headerSubtitle {
    margin: 0;
    margin-bottom: 40px;
    color: #2F2F2F;

    font-family: $manrope;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 3.64px;
}

.iconList {
    display: flex;
    justify-content: space-between;
}

.iconList__cell + .iconList__cell {
    margin-left: 20px;
}

.iconList__cell {
    flex-grow: 2;
    width: 100%;
    //width: 25%;
    min-height: 237px;
    padding: 30px 40px;
    border: 1px solid rgba(47, 47, 47, 0.20);

    border-radius: 20px;

    &::before {
        content: "";

        display: block;
        margin: 0 auto;
        margin-bottom: auto;
    }

    &Img {
        width: 40px;
        height: 40px;
        margin-bottom: 35px;
    }
}

.iconList__title {
    margin: 0;
    margin-bottom: 10px;

    color: rgba(0, 0, 0, 0.70);

    font-family: $manrope;
    font-size: 20px;
    font-weight: 700;
    line-height: 150%;
}

.iconList__descr {
    margin: 0;

    color: rgba(0, 0, 0, 0.70);
    font-family: $roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
}
